import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { SectionHeader } from 'src/components/molecules/Section';
import { CommunitySection } from 'src/components/organisms/CommunitySection';
import { useSiteMeta } from 'src/hooks/use-site-meta';
import * as sys from '../../system';
import * as css from './drawer-community.css';

function formatCount(count) {
  if (typeof count !== 'number') return count;
  return count.toLocaleString('en-US');
}

const CountHeadline = styled.h2`
  margin: 0;
`;

const CenteredCard = styled(Card)`
  margin: 0;
  font-weight: medium;
  text-align: center;
`;

export default function LegacySection({ heading, content, contentNode, ctas, customData }) {
  const staticData = useStaticQuery(query);
  const siteMeta = useSiteMeta();
  const { downloads, forumMembers, contributors } = siteMeta.numbers;
  customData = JSON.parse(customData);
  return (
    <CommunitySection
      css={[sys.frame, css.section]}
      profileImages={staticData.memberImages.nodes}
      {...customData?.flags}
    >
      <SectionHeader>
        <h2>{heading}</h2>
        {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      </SectionHeader>

      <Grid columns="1fr 1fr 1fr">
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(downloads)}+</CountHeadline>
          <strong>Downloads</strong>
        </CenteredCard>
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(forumMembers)}+</CountHeadline>
          <strong>Forum Members</strong>
        </CenteredCard>
        <CenteredCard centered spacious>
          <CountHeadline>{formatCount(contributors)}+</CountHeadline>
          <strong>Contributors</strong>
        </CenteredCard>
      </Grid>
      {ctas.length > 0 && (
        <>
          <br />
          <InlineList centered style={{ margin: 0 }}>
            {ctas.map((cta, i) => (
              <li key={i}>
                <Button to={cta.url} aria-label={cta.a11yText || cta.text} secondary={i > 0}>
                  {cta.text}
                </Button>
              </li>
            ))}
          </InlineList>
        </>
      )}
    </CommunitySection>
  );
}

const query = graphql`
  query {
    memberImages: allFile(
      filter: {
        relativePath: {
          in: [
            "images/profile/1263428.jpeg"
            "images/profile/10572261.jpeg"
            "images/profile/10116000.jpeg"
            "images/profile/3694634.jpeg"
            "images/profile/2381319.jpeg"
            "images/profile/13789524.jpeg"
            "images/profile/1426587.jpeg"
            "images/profile/3694484.png"
            "images/profile/soumya-mukherjee.jpg"
            "images/profile/siddhartha_lahiri.jpg"
            "images/profile/abin_joy.jpg"
            "images/profile/akshay_agarwal.jpg"
            "images/profile/alexis_smirnov.jpg"
            "images/profile/aman_tiwari.jpg"
            "images/profile/andrei_sousa.jpg"
            "images/profile/ankit_sinha.jpg"
            "images/profile/arshid.jpg"
            "images/profile/arthur_temporim.jpg"
            "images/profile/bikash_kumar_sundaray.jpg"
            "images/profile/dmitrii_starov.jpg"
            "images/profile/dominic_breuker.jpg"
            "images/profile/eric_bonfadini.jpg"
            "images/profile/federico_marinelli.jpg"
            "images/profile/fedya_skitsko.jpg"
            "images/profile/feynman_liang.jpg"
            "images/profile/gabriela_barrozo_guedes.jpg"
            "images/profile/greg_stephens.jpg"
            "images/profile/greg_werner.jpg"
            "images/profile/guan_jianyu.jpg"
            "images/profile/guan_wang.jpg"
            "images/profile/jackson_zhipeng_chang.jpg"
            "images/profile/jahir_fiquitiva.jpg"
            "images/profile/jinchao_yu.jpg"
            "images/profile/jonatan_dellagostin.jpg"
            "images/profile/jose_marcos_rodriguez_fernandez_.jpg"
            "images/profile/kevin_castro.jpg"
            "images/profile/luke_jordan.jpg"
            "images/profile/mark_collins.jpg"
            "images/profile/matheus_miranda_lacerda.jpg"
            "images/profile/max_mikhaylov.jpg"
            "images/profile/nahid_alam.jpg"
            "images/profile/nengwe_ntafam_roger.jpg"
            "images/profile/nguyen_dinh_tam.jpg"
            "images/profile/noufal_salim.jpg"
            "images/profile/rahul_prabhakar.jpg"
            "images/profile/rana_mostafa.jpg"
            "images/profile/rania_zyane.jpg"
            "images/profile/ritwik_g.jpg"
            "images/profile/samrudha_kelkar.jpg"
            "images/profile/santiago_gonzalez_toral.jpg"
            "images/profile/shota_makino.jpg"
            "images/profile/sibish_basheer.jpg"
            "images/profile/simon_senkl.jpg"
            "images/profile/sri_yogesh_k.jpg"
            "images/profile/surya_kasturi.jpg"
            "images/profile/ujjwal_puri.jpg"
            "images/profile/utkal_sinha.jpg"
            "images/profile/vishal_jagtap.jpg"
            "images/profile/yifan_zhang.jpg"
          ]
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 80, width: 80)
        }
      }
    }
  }
`;
